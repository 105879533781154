import BaseRequest from "@/request/baseRequest.js";
const http = new BaseRequest()
const headers = {
    'content-type': 'application/json'
}
export default {
    login(data) {
        return http.post('/seller/user/login', data, headers)
    },
    //注册
    register(data) {
        return http.post('/seller/user/register', data, headers)
    },

    //忘记密码
    forgetPassword(data) {
        return http.post(`/seller/user/forgetPassword`, data, { 'content-type': 'application/json' })
    },

    //获取用户信息
    getUserInfo(data) {
        return http.post(`/seller/user/getUserInfo`, data)
    },

    // 获取验证码手机
    getPhoneCode(data) {
        return http.post('/seller/user/getPhoneCode', data, headers)
    },

    // 获取验证码邮箱
    getEmailCode(data) {
        return http.post('/seller/user/getEmailCode', data, headers)
    },

    //轮播图
    getBanner(data){
        return http.get('/seller/banner/getBanner',data,headers)
    },
    //获取品牌
    getBrand(data){
        return http.get('/seller/brand/getBrand',data,headers)
    },

//获取商品分类
    getCategory(data) {
        return http.get('/seller/category/getCategory', data)
    },
    queryProductsInAdmin(data){
        return http.post('/seller/products/queryProductsInAdmin',data,headers)
    },

    //主页查询产品
    homeQueryProducts(data){
        return http.post('/seller/products/homeQueryProducts',data,headers)
    },
    //商品详情
    queryUpProductTime(data){
        return http.post('/seller/products/queryUpProductTime',data,headers)
    },
    //查询所有店铺
    queryShops(data){
        return http.post('/seller/shop/queryShops',data,headers)
    },
    //查询单个店铺
    querySingleShop(data){
        return http.post('/seller/shop/queryShop',data,headers)
    },
    //查询店铺产品
    queryProductsByShops(data){
        return http.post('/seller/shop/queryProductsByShops',data,headers)
    },
    //查看商品好评   （seller文档无此接口）
    queryProductsComments(data){
        return http.post('/seller/buy/products/queryProductsComments',data,headers)
    },

    //客服链接
    getLiveChat(data){
        return http.get(`/seller/liveChat/getLiveChat`, data)
    },

    //获取地址集
    getAddress(data){
        return http.get(`/seller/banner/getAddress`, data)
    },

    //删除地址
    delAddress(data){
        return http.post(`/seller/banner/delAddress`,  data,{'content-type':'application/json'})
    },

    //添加地址
    addAddress(data){
        return http.post(`/seller/banner/addAddress`,  data,{'content-type':'application/json'})
    },

    //修改密码
    updatePassword(data){
        return http.post(`/seller/user/updatePassword`, data,{'content-type':'application/json'})
    },

    //查看订单
    queryOrderHistory(data){
        return http.post(`/seller/order/queryOrder`, data,{'content-type':'application/json'})
    },

    //退货 取消订单
    returnOrder(data){
        return http.post(`/seller/buy/returnOrder`, data,{'content-type':'application/json'})
    },

    //确认收货
    confirmOrder(data){
        return http.post(`/seller/buy/confirmOrder`, data,{'content-type':'application/json'})
    },

    //查看订单详情
    queryOrderDetail(data){
        return http.post(`/seller/order/queryOrderDetail`, data,{'content-type':'application/json'})
    },

    // 巴西支付开启关闭
    getBrazilianPaymentState(data) {
        return http.get(`/seller/user/getIsThirdpartyPayment`, data)
    },

    //获取支付渠道
    getPayChannel(data){
        return http.get(`/seller/pay/getPayChannel`, data)
    },

    //巴西支付 充值
    brazilRecharge(data){
        return http.post(`/seller/pay/recharge/thirdparty/brazil`, data,{'content-type':'application/json'})
    },

    //充值接口
    currencyRecharge(data){
        return http.post(`/seller/pay/recharge`, data,{'content-type':'application/json'})
    },

    //提现接口
    currencyWithdraw(data){
        return http.post(`/seller/pay/withdraw`, data,{'content-type':'application/json'})
    },

    //获取提现汇率
    getWithdrawFee(data){
        return http.get(`/seller/wFee/getWfee`, data)
    },

    //上传文件
    uploadFile(data){
        return http.post(`/seller/file/uploadFile`,data,{ 'content-type': 'application/x-www-form-urlencoded'})
    },

    //上传文件 （seller 专上传店铺banner文件）
    uploadShopBannerFile(data){
        return http.post(`/seller/shopFile/uploadShopBannerFile`,data)
    },

    //提现配置地址查询
    queryUserWallet(data){
        return http.get(`/seller/userWallet/queryUserWallet`, data)
    },

    //编辑提现地址修改
    editUserWallet(data){
        return http.post(`/seller/userWallet/editUserWallet`, data,{'content-type':'application/json'})
    },

    //删除钱包提现的配置
    delUserWallet(data){
        return http.post(`/seller/userWallet/delUserWallet`, data,{'content-type':'application/json'})
    },

    //用户添加钱包地址
    addUserWallet(data){
        return http.post(`/seller/userWallet/addUserWallet`, data,{'content-type':'application/json'})
    },

    //创建 卖家店铺  （新版暂时未使用: 此接口是 未有买家账户 直接创建卖家店铺的接口）
    createSellerShop(data){
        return http.post(`/seller/shop/createShop`, data,{'content-type':'application/json'})
    },

    //成为卖家 (开店  已有买家账户  --->  卖家账户开店)
    becomeSeller(data){
        return http.post(`/seller/shop/changeShop`, data,{'content-type':'application/json'})
    },

    //获取域名
    getDomainLink(data){
        return http.get(`/seller/host/list`, data)
    },

    //主页查询畅销产品
    activeDemandProducts(data){
        return http.post(`/seller/products/homeQueryActiveDemandProducts`, data,{'content-type':'application/json'})
    },

    //主页查询每日上新产品
    newProducts(data){
        return http.post(`/seller/products/homeQueryNewProducts`, data,{'content-type':'application/json'})
    },

    //获取保证金记录
    getEarnestMoneyRecord(data){
        return http.post(`/seller/sel/getEarnestMoneyRecord`, data,{'content-type':'application/json'})
    },

    //获取保证金
    getEarnestMoney(data){
        return http.post(`/seller/sel/getShopLevel`, data,{'content-type':'application/json'})
    },

    //余额充值保证金
    balanceRechargeMargin(data){
        return http.post(`/seller/sel/addShopMoney`, data,{'content-type':'application/json'})
    },

    //获取分享数据
    getShareData(data){
        return http.get(`/seller/share`, data)
    },

    //获取共享明细
    getShareDetail(data){
        return http.post(`/seller/share/detail`, data,{'content-type':'application/json'})
    },

    //店内查询所有商品：（sortField：最新上架 create_time , 热销商品 sales_volume）
    queryShopNewProducts(data){
        return http.get(`/seller/pcshop/queryShopNewProducts`, data)
    },

    //获取平台 logo name   (买家用户头像也是使用 平台logo)
    getPlatformInfo(data){
        return http.get(`/seller/logo/info`, data)
    },

    //查询店长推荐商品
    queryShopRecommendsProducts(data){
        return http.get(`/seller/pcshop/queryShopRecommendsProducts`, data)
    },

    //店内查询所有商品：（sortField：最新上架 create_time , 热销商品 sales_volume）
    queryShopAllProducts(data){
        return http.get(`/seller/pcshop/queryShopAllProducts`, data)
    },


    //店铺分类接口
    queryShopCategory(data){
        return http.get(`/seller/pcshop/queryShopCategory`,data)
    },

    //获取物流模板信息
    getLogisticsData(data){
        return http.get(`/seller/logistics/list`, data)
    },

    //最近交易记录 （包含充提）
    queryRecentTranRecord(data){
        return http.post(`/seller/trans/queryRecentTranRecord`, data,{'content-type':'application/json'})
    },

    // 系统配置接口，by key
    getHashMapByKey(data) {
        return http.get(`/seller/mallHashmap/getHashMapByKey`, data);
    },

    //卖家下单发货支付
    payOrder(data){
        return http.post(`/seller/order/payOrder`, data,{'content-type':'application/json'})
    },

    //店铺数据展示 折线图统计
    getStatistics(data){
        return http.get(`/seller/shop/dataShow`, data)
    },

    //获取各种盈利(当天、七天、近30天)
    getAllKindsProfit(data){
        return http.post(`/seller/shop/getAllKindsProfit`, data,{'content-type':'application/json'})
    },

    //获取批发中心商品
    queryBatchProducts(data){
        return http.post(`/seller/products/queryBatchProducts`, data,{'content-type':'application/json'})
    },

    //卖家上架产品
    addProducts(data){
        return http.post(`/seller/products/addProducts`, data,{'content-type':'application/json'})
    },

    //商品管理，查询已上架产品
    queryUpProducts(data){
        return http.post(`/seller/products/queryUpProducts`, data,{'content-type':'application/json'})
    },

    //卖家下架产品
    deleteProducts(data){
        return http.post(`/seller/products/deleteProducts`, data,{'content-type':'application/json'})
    },

    //查询账单 账变明细
    queryBillData(data){
        return http.post(`/seller/mallBill/queryBill`, data,{'content-type':'application/json'})
    },

    //提现记录
    getWithdrawList(data){
        return http.post(`/seller/pay/withdrawList`, data,{'content-type':'application/json'})
    },

    //充值记录
    getRechargeList(data){
        return http.post(`/seller/pay/rechargeList`, data,{'content-type':'application/json'})
    },

    //三方充值订单
    getThreeRechargeList(data){
        return http.post(`/seller/pay/thirdparty/brazil/rechargeList`, data,{'content-type':'application/json'})
    },

    //获取聊天
    getChat(data){
        return http.post(`/seller/chat/getChat`, data,{'content-type':'application/json'})
    },

    //获取聊天详情
    getChatDetail(data){
        return http.post(`/seller/chat/getChatDetail`, data,{'content-type':'application/json'})
    },

    //发送聊天
    sendChat(data){
        return http.post(`/seller/chat/sendChat`, data,{'content-type':'application/json'})
    },

    //修改设置支付密码
    updateTransPassword(data){
        return http.post(`/seller/user/updateTransPassword`, data,{'content-type':'application/json'})
    },

    //首次设置支付密码
    setTransPassword(data){
        return http.post(`/seller/user/settingTransPassword`, data,{'content-type':'application/json'})
    },

    //根据店铺用户获取文件
    getFileByShopUserName(data) {
        return http.get(`/seller/shopFile/getFileByShopUserName`, data);
    },

    //获取流量列表
    getShopView(data){
        return http.post(`/seller/sel/getShopView`, data,{'content-type':'application/json'})
    },

    //流量充值
    payShopView(data){
        return http.post(`/seller/sel/payShopView`, data,{'content-type':'application/json'})
    },

    //修改店铺名店铺头像
    updateMallUserShop(data){
        return http.post(`/seller/sel/updateMallUserShop`, data,{'content-type':'application/json'})
    },


    //根据店铺用户获取文件
    getShopFile(data) {
        return http.get(`/seller/shopFile/getFile`, data);
    },


    //删除店铺banner 图片
    deleteShopBannerFile(data){
        return http.post(`/seller/shopFile/deleteShopBannerFile`,qs.stringify(data))
        // return http.post(`/seller/shopFile/deleteShopBannerFile`,data,{'content-type':'application/json'})
    },

    // 流量购买入口开关
    visitorsLock(data) {
        return http.get(`/seller/user/isOpenBuyWaitVisitors`, data)
    },

    //关于我们
    getAboutUs(data){
        return http.get(`/seller/logo/aboutUs`, data)
    },

    //获取公告
    noticeList(data){
        return http.get(`/seller/broadcast/list`, data)
    },

    //获取首页，广告等 banner 图片
    getBannerByName(data){
        return http.get(`/seller/banner/getBannerByName`, data)
    },

    //PC-获取聊天
    getPcChat(data) {
        return http.post(`/seller/chat/getPcChat`, data, headers)
    },

    //个人中心 banner
    getCentreBanner(data){
        return http.post(`/seller/logo/getLogo`, data,{'content-type':'application/json'})
    },

    //数据统计待评价
    getRemainCommentsOrder(data){
        return http.post(`/seller/comments/queryRemainCommentsOrder`, data,{'content-type':'application/json'})
    },
//手动选择获取列表品
    getUploadCategory(data){
        return http.get(`/seller/category/getCategory`, data,{'content-type':'application/x-www-form-urlencoded'})
    },
//     自动选择获取商品
    queryPcBatchProducts(data){
        return http.post(`/seller/products/queryPcBatchProducts`, data,{'content-type':'application/json'})
    },
//     获取商品评论 seller/comments/queryProductsCommentsManager
    queryProductsCommentsManager(data){
        return http.post(`/seller/comments/queryProductsCommentsManager`, data,{'content-type':'application/json'})
    },
    // 商品评论
    queryProductsCommentss(data){
        return http.post('/seller/comments/queryProductsComments',data,headers)
    },
    getUploadFile(data,header){
        return http.post(`/seller/file/uploadFile`,data,header)
    },
    //商鋪認證上傳 seller/shop/restShopValid
    restShopValid(data){
        return http.post(`/seller/shop/restShopValid`,data,{'content-type':'application/json'})
    },
//     /seller/mallProductsTactics/list ai 上架
    mallProductsTacticsList(data){
        return http.post(`/seller/mallProductsTactics/list`,data,{'content-type':'application/json'})
    },
//     /seller/mallProductsTactics/info AI策略详情
    mallProductsTacticsInfo(data){
        return http.get(`/seller/mallProductsTactics/info`, data,{'content-type':'application/x-www-form-urlencoded'})
    },
//     seller/products/queryProductsTactics
    queryProductsTactics(data){
        return http.post(`/seller/products/queryProductsTactics`,data,{'content-type':'application/json'})
    },
//     seller/pay/balanceOfClearing
    balanceOfClearing(data){
        return http.post(`/seller/pay/balanceOfClearing`,data,{'content-type':'application/json'})
    },
//     seller/products/productsTacticsPay
    productsTacticsPay(data){
        return http.post(`/seller/products/productsTacticsPay`,data,{'content-type':'application/json'})
    },
//     seller/upHot/timeDict
    timeDict(data){
        return http.post(`/seller/upHot/timeDict`,data,{'content-type':'application/json'})
    },
//     seller/mallRegion/all
    mallRegion(data){
        return http.post(`/seller/mallRegion/all`,data,{'content-type':'application/json'})
    },
//     seller/upHot/order
    upHotorder(data){
        return http.post(`/seller/upHot/order`,data,{'content-type':'application/json'})
    },
//     /seller/upHot/pushCode
    pushCode(data){
        return http.post(`/seller/upHot/pushCode`,data,{'content-type':'application/json'})
    },
//     seller/upHot/savePushConf
    savePushConf(data){
        return http.post(`/seller/upHot/savePushConf`,data,{'content-type':'application/json'})
    },
//     getDataByPushCode
    getDataByPushCode(data){
        return http.get(`/seller/upHot/getDataByPushCode`,data,{'content-type':'application/x-www-form-urlencoded'})
    },
    // /seller/upHot/fastPushPay
    fastPushPay(data){
        return http.post(`/seller/upHot/fastPushPay`,data,{'content-type':'application/json'})
    },
//     seller/upHot/orientPushPay
    orientPushPay(data){
        return http.post(`/seller/upHot/orientPushPay`,data,{'content-type':'application/json'})
    },
//     seller/upHot/orderHotData
    orderHotData(data){
        return http.get(`/seller/upHot/orderHotData`, data,{'content-type':'application/x-www-form-urlencoded'})
    },
//     seller/shop/dataOrderShow
    dataOrderShow(data){
        return http.get(`/seller/shop/dataOrderShow`, data,{'content-type':'application/x-www-form-urlencoded'})
    },
    getLogo(data){
        return http.post('/seller/logo/getLogo',data,headers)
    },
    orderInform(data){
        return http.post('/seller/orderInform/list',data,{'content-type':'application/json'})
    },
    orderInformCount(data){
        return http.post('/seller/orderInform/count',data,{'content-type':'application/json'})
    },
    // 获取店铺等级map
    getShopLevelMap(data){
        return http.get('/seller/shop/shopLevel',data,headers)
    },
    registerCodeIsEnable(data) {
        return http.get('/seller/user/registerCodeIsEnable', data, headers)
    },
    addUserWalletBank(data){
        return http.post('/seller/userWallet/addUserWalletBank',data,headers)
    },
    getWalletFreeze(data){
        return http.get('/seller/user/getWalletFreeze',data,headers)
    },
    editUserWalletBank(data) {
        return http.post('/seller/userWallet/editUserWalletBank', data, headers)
    },
}
