import { createWebHashHistory, createRouter } from "vue-router";
const route = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'layout',
            redirect: '/home',
            component: () => import('@/layout/index.vue'),
            children: [{
                path: '/',
                name: 'home',
                redirect: '/dataCenter',
                component: () => import('@/seller/views/home/index.vue'),
                meta: {
                    title: '首页'
                },
                children: [{
                    path: '/orders',
                    name: 'orders',
                    component: () => import('@/seller/views/orders/index.vue'),
                    meta: {
                        title: '订单'
                    },
                }, {
                    path: '/dataCenter',
                    name: 'dataCenter',
                    component: () => import('@/seller/views/dataCenter/index.vue'),
                    meta: {
                        title: '数据中心'
                    },
                }]
            },
            {
                path: '/tool',
                name: 'tool',
                redirect: '/beHot',
                component: () => import('@/seller/views/tools/index.vue'),
                meta: {
                    title: '工具'
                },
                children: [{
                    path: '/wholesaleCenter',
                    name: 'wholesaleCenter',

                    component: () => import('@/seller/views/tools/page/wholesaleCenter/index.vue'),
                    meta: {
                        title: '工具'
                    },
                }, {
                    path: '/aiDetail',
                    name: 'aiDetail',

                    component: () => import('@/seller/views/tools/page/aiDetail/index.vue'),
                    meta: {
                        title: 'ai上架'
                    },
                }, {
                    path: '/productManagement',
                    name: 'productManagement',

                    component: () => import('@/seller/views/tools/page/productManagement/index.vue'),
                    meta: {
                        title: '商品管理'
                    },
                }, {
                    path: '/orderManagement',
                    name: 'orderManagement',

                    component: ()=> import('@/seller/views/orders/index.vue'),
                    meta:{
                        title:'订单管理'
                    },
                }, {
                    path: '/margin',
                    name: 'margin',

                    component: () => import('@/seller/views/tools/page/margin/index.vue'),
                    meta: {
                        title: '保证金'
                    },
                }, {
                    path: '/evaluationManagement',
                    name: 'evaluationManagement',

                    component: () => import('@/seller/views/tools/page/evaluationManagement/index.vue'),
                    meta: {
                        title: '评价管理'
                    },
                }, {
                    path: '/beHot',
                    name: 'beHot',

                    component: () => import('@/seller/views/tools/page/beHot/index.vue'),
                    meta: {
                        title: '上热门'
                    },
                //
                },{
                    path:'/evaluationDetail',
                    name:'evaluationDetail',
                    component: ()=> import('@/seller/views/tools/page/evaluationManagement/evaluationDetail.vue'),
                    meta:{
                        title:'评论详情'
                    },
                    //     evaluationDetail
                },{
                    path:'/dataDetail',
                    name:'dataDetail',
                    component: ()=> import('@/seller/views/tools/page/beHot/dataDetail.vue'),
                    meta:{
                        title:'热门数据'
                    },
                    //     evaluationDetail
                }]
            }, {
                path: '/personal',
                name: 'personal',
                component: () => import('@/seller/views/personal/index.vue'),
                redirect: '/entrust',
                meta: {
                    title: '个人中心',
                    closeFoot: true
                },
                children: [

                    {
                        path: '/deposit',
                        name: 'deposit',
                        component: () => import('@/seller/views/personal/component/deposit.vue'),
                        meta: {
                            title: '存款',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/withdraw',
                        name: 'withdraw',
                        component: () => import('@/seller/views/personal/component/withdraw.vue'),
                        meta: {
                            title: '提现',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/billingDetails',
                        name: 'billingDetails',
                        component: () => import('@/seller/views/personal/component/billingDetails.vue'),
                        meta: {
                            title: '账单明细',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/bindingUSDT',
                        name: 'bindingUSDT',
                        component: () => import('@/seller/views/personal/component/bindingUSDT.vue'),
                        meta: {
                            title: '绑定USDT',
                            closeFoot: true
                        },
                    },

                    {
                        path: '/entrust',
                        name: 'entrust',
                        component: () => import('@/seller/views/personal/component/entrust.vue'),
                        meta: {
                            title: '我的委托',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/myInvite',
                        name: 'myInvite',
                        component: () => import('@/seller/views/personal/component/myInvite.vue'),
                        meta: {
                            title: '我的邀请',
                            closeFoot: true
                        },
                    },


                    {
                        path: '/exchange',
                        name: 'exchange',
                        component: () => import('@/seller/views/personal/component/exchange.vue'),
                        meta: {
                            title: '劃轉',
                            closeFoot: true
                        },
                    },
                    {
                        path: '/changPwd',
                        name: 'changPwd',
                        component: () => import('@/buyer/views/personal/component/changPwd.vue'),
                        meta: {
                            title: '劃轉',
                            closeFoot: true
                        },
                    },{
                        path: '/shopInfo',
                        name: 'shopInfo',
                        component: () => import('@/seller/views/shopInfo/index.vue'),
                        meta: {
                            title: '劃轉',
                            closeFoot: true
                        },
                    },


                ]
            }, {
                    path: '/sysMessage',
                    name: 'sysMessage',

                    component: () => import('@/seller/views/sysMessage/index.vue'),
                    meta: {
                        title: '保证金'
                    },
                },
            ]
        }
    ]
})
// route.beforeEach((to,from,next)=>{
//     const sysStore = systemStroe()
//     const user = userStore()
//     if(!xb.router.hasRoute(to.name)){
//         next('/404')
//         return
//     }
//     if((!user.userInfo.token || user.userInfo.token == '') && to.path !== '/login'){
//         next(`/login?redirect=${to.path}`)
//     }else{
//         if(user.userInfo.token && to.name == 'login'){
//             next('/')
//             return;
//         }
//     }
//     sysStore.routerBreadList = to.matched.filter(item=>item.name!='layout' && item.name != '404' && item.name != 'login')
//     if(!sysStore.history.some(it=>it.name == to.name) && !(to.name == '404'||to.name == 'login')) sysStore.history.push(to)
//     next()
// })
export default route
